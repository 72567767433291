import { computed, inject, Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { getEmployeeById, selectRouterParam } from '@fc-store/selectors';
import { ChatWsService } from '../services/chat-ws.service';
import { ChatStore } from './chat.store';
import { ChatBaseMessage, ChatMessage } from '../models/chat-message';
import { Employee } from '@fc-core/models';
import { MessageType } from '../models/message-type';
import { TokenData } from '../../auth/models/token-data';
import moment from 'moment-timezone';
import { Emoji } from '@fc-shared/ui/status-set-dialog/status-set-dialog.component';
import { ChatApiService } from '../services/chat-api.service';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { Channel } from '../models/channel';
import * as amplitude from '@amplitude/analytics-browser';
import { MediaModel } from '@fc-vehicles/service/media-model';
import { ChatAttachmentsApiService } from '../services/chat-attachments-api.service';
import { ChatAttachmentsStore } from './chat-attachments.store';
import { Op } from 'quill';
import { LinkPreviewData } from '../services/chat-links-api.service';
@Injectable({
  providedIn: 'root',
})
export class ChatFacadeService {
  tokenData: TokenData = JSON.parse(
    atob(localStorage.getItem('token').split('.')[1]),
  );
  chatWs = inject(ChatWsService);
  chatStore = inject(ChatStore);
  globalStore = inject(Store);
  router = inject(Router);
  api = inject(ChatApiService);
  attachmentsApi = inject(ChatAttachmentsApiService);
  chatAttachmentsStore = inject(ChatAttachmentsStore);

  currentChannelId = this.globalStore.selectSignal(
    selectRouterParam('channelId'),
  );

  currentChannel = computed(() =>
    this.chatStore.selectors
      .channels()
      .find((channel) => channel.id === this.currentChannelId()),
  );

  messages = this.chatStore.selectors.messages;
  channels = this.chatStore.selectors.channels;
  online = this.chatStore.selectors.online;

  constructor() {
    this.chatWs.chatStream$.subscribe((message: ChatMessage) =>
      this.processMessage(message),
    );
  }

  getOnlineEmployeeById = (id: string): Signal<boolean> => {
    return computed(() => this.online().includes(id));
  };

  getEmployeeById = (id: string) =>
    this.globalStore.selectSignal(getEmployeeById(id));

  getChannels = () => this.chatStore.getChannels();

  getChannelById = (channelId: string): Promise<Channel> =>
    this.chatStore.getChannelById(channelId);

  currentChannelMessages(): Signal<ChatMessage[]> {
    return computed(() => {
      const channelId = this.currentChannelId();
      if (!channelId) return [];
      const messages = this.chatStore.selectors.messages()[channelId] || [];
      return Array.from(messages.values());
    });
  }

  getOnlineMembers = (channel: Channel): Signal<Employee[]> =>
    computed(() =>
      channel.members
        .filter((member) => this.getOnlineEmployeeById(member))
        .map((employee) => this.getEmployeeById(employee)()),
    );

  getOfflineMembers = (channel: Channel): Signal<Employee[]> =>
    computed(() =>
      channel.members
        .filter((member) => !this.getOnlineEmployeeById(member))
        .map((employee) => this.getEmployeeById(employee)()),
    );

  getChannelUnreadMessagesCount = (channel: Channel): Signal<number> =>
    computed(() => {
      const messages: ChatMessage[] = Array.from(
        this.chatStore.selectors.messages()[channel.id]?.values() || [],
      );
      if (channel.unreadMessagesCount && messages.length === 0)
        return channel.unreadMessagesCount;
      return messages.filter((message) => this.isMessageUnread(message)).length;
    });

  getAllUnreadMessages = (): number => {
    const messages = this.chatStore.selectors.messages();
    const channels = this.chatStore.selectors.channels();
    const unreadMessages = channels.map((channel) => {
      if (channel.unreadMessagesCount && !messages[channel.id]) {
        return channel.unreadMessagesCount;
      }
      if (messages[channel.id] && messages[channel.id].size > 0) {
        const channelMessages = Array.from(messages[channel.id].values());
        return channelMessages.filter((message) =>
          this.isMessageUnread(message),
        ).length;
      }
      return 0;
    });

    return unreadMessages.reduce((acc, curr) => acc + curr, 0);
  };

  isMessageMine = (message: ChatMessage): boolean =>
    message.author === this.tokenData.employee_id;

  isMessageUnread = (message: ChatMessage): boolean => {
    if (!this.isMessageMine(message)) {
      return !Array.from(message.meta.viewed || []).some(
        (view) => view.employeeId === this.tokenData.employee_id,
      );
    }
    return false;
  };

  directChannelEmployee(channel: Channel): Signal<Employee> {
    const currentEmployeeId = this.tokenData.employee_id;
    const otherEmployeeId = channel.members.find(
      (member) => member !== currentEmployeeId,
    );
    return this.getEmployeeById(otherEmployeeId);
  }

  async sendMessage(
    message: string,
    files: MediaModel[] = [],
    linkPreviews: LinkPreviewData[] = [],
    delta: Op[] | null = null,
  ): Promise<void> {
    const messagePayload: ChatMessage = {
      type: MessageType.MESSAGE,
      meta: {
        published: moment().toISOString(),
      },
      channel: this.currentChannelId(),
      content: {
        text: JSON.stringify(delta),
        attachments: files.map((file) => file.id),
        previews: linkPreviews,
      },
      id: uuidv4(),
    };
    this.chatWs.sendMessage(messagePayload);

    amplitude.track('chat_message_sent', {
      user_id: this.tokenData.employee_id,
      channel_id: this.currentChannelId(),
      message_id: messagePayload.id,
      timestamp: new Date().toISOString(),
      message_length: message.length,
    });
  }

  createChannel(channel: Channel): Promise<Channel> {
    return this.chatStore.createChannel(channel);
  }

  async updateChannel(channel: Channel): Promise<any> {
    const updatedChannel = await this.api.updateChannel(channel);
    this.chatStore.updateChannel(updatedChannel);
  }

  async deleteChannel() {
    await this.api.deleteChannel(this.currentChannelId());
    this.router.navigate(['/chats']);
    this.chatStore.deleteChannel(this.currentChannelId());
  }

  async getChannelMessages(): Promise<ChatMessage[]> {
    const messages = await this.api.getChannelMessages(this.currentChannelId());
    this.chatStore.setMessages(messages, this.currentChannelId());
    return messages;
  }

  async getAttachmentById(id: number): Promise<MediaModel> {
    const attachment = await this.attachmentsApi.getAttachment(id.toString());
    this.chatAttachmentsStore.setAttachment(attachment);
    return attachment;
  }

  async deleteAttachment(id: number) {
    await this.attachmentsApi.deleteFile(id.toString());
    this.chatAttachmentsStore.deleteAttachment(id);
  }

  private async processMessage(message: ChatBaseMessage) {
    if (!message) return;
    if (message.type === MessageType.MESSAGE) {
      await this.playSound();
      if (
        !this.chatStore.selectors
          .channels()
          .find((channel) => channel.id === message.channel)
      ) {
        // TODO: this is temporary until we have a channel creation event
        await this.getChannelById(message.channel);
      }
      this.chatStore.upsertMessage(message);
    } else if (message.type === MessageType.ONLINE) {
      this.chatStore.setOnline(message.employees);
    } else if (message.type === MessageType.UPDATE) {
      this.chatStore.upsertMessage(message);
    } else if (message.type === MessageType.DELETE) {
      this.chatStore.deleteMessage(message.id, message.channel);
    }
  }

  private async playSound() {
    try {
      const audio = new Audio('assets/sounds/notifications.mp3');
      audio.volume = 0.5;
      await audio.play();
    } catch (error) {
      console.error('Error playing sound', error);
    }
  }

  async sendEdit(message: ChatMessage): Promise<void> {
    const updatedMessage = await this.api.patchMessage({
      id: message.id,
      content: {
        ...message.content,
        text: message.content.text,
      },
    });

    this.chatStore.upsertMessage(updatedMessage);

    amplitude.track('chat_message_edited', {
      user_id: this.tokenData.employee_id,
      channel_id: this.currentChannelId(),
      message_id: message.id,
      timestamp: new Date().toISOString(),
    });
  }

  async sendReaction(message: ChatMessage, emoji: Emoji): Promise<void> {
    const updatedMessage = await this.api.sendReaction(
      message.id,
      emoji.native,
    );

    this.chatStore.upsertMessage(updatedMessage);

    amplitude.track('chat_reaction_added', {
      user_id: this.tokenData.employee_id,
      channel_id: this.currentChannelId(),
      message_id: message.id,
    });
  }

  async markMessageAsViewed(message: ChatMessage): Promise<void> {
    const updatedMessage = await this.api.sendViewed(message.id);
    this.chatStore.upsertMessage(updatedMessage);
  }

  async sendDelete(message: ChatMessage): Promise<void> {
    await this.api.deleteMessage(message.id);
    this.chatStore.deleteMessage(message.id, message.channel);

    amplitude.track('chat_message_deleted', {
      user_id: this.tokenData.employee_id,
      channel_id: message.channel,
      message_id: message.id,
      timestamp: new Date().toISOString(),
    });
  }
}
